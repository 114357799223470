const posts = [

	{
		title: "Container Ships: Revolutionizing Global Trade",
		image: "https://t4.ftcdn.net/jpg/03/16/31/81/360_F_316318196_CnjRlrAOqYXNcm35kOuOmxTwj7wB2nJ4.jpg",
		text: "",
		author: "Rafail Kyritsis",
		date: "May/26/2023",
		tag: "#assignment"
	
	},
	{
		title: "HR Metrics",
		image: "https://kumospace.mo.cloudinary.net/https://content.kumospace.com/hubfs/Guide-to-People-Analytics.jpg?tx=w_responsive:fallback-max-width_1448;fallback-max-width-mobile_720",
		text: "",
		author: "Rafail Kyritsis",
		date: "May/26/2023",
		tag: "#assignment"
	
	}

]
 

export default posts;

